import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe to truncate a String
 */
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
/**
 * Cut the String after the limit and add ... when the String is shorter than the limit, else leave the String as it is
 * @param value 
 * @param limit 
 * @param oneLiner 
 * @returns 
 */
  transform(value: string, limit: number, oneLiner) {
    if(value != null){
      if (oneLiner == true) {
        return value.length > limit ? value.substr(0, limit) + ' ...' : value;
      } else {
        if ((value?.substr(0, limit)?.length <= (limit) / 2)) {
          return value + '\n' + '\n';
        } else {
          return value.length > limit ? value.substr(0, limit-8) + ' ...' : value;
        }
  
      }
    }

  }
}